<template>
  <!-- 隐私协议 -->
  <pre class="agreementP" v-html="content"></pre>
</template>
<script>
import { getDetail } from '@/api/employeedata.js'
import { Message } from 'element-ui'
export default {
  name: 'personalCenter-agreement',
  data() {
    return {
      content: ''
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      getDetail({ onlyFlag: 'YINSIZHENGCE' }).then(res => {
        if (res && res.code === '000000') {
          this.content = res.data.content
        } else {
          Message({
            message: '隐私协议查询失败！',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.agreementP {
  background: #fff;
  height: calc(100% - 40px);
  padding: 30px;
  margin: 20px 30px;
  overflow-y: auto;
}
</style>
